import React from "react";
import Layout from "components/Layout";
import { CartContents } from "../components";

function CartPage() {
  return (
    <Layout
      pageTitle="Cart - Reprocess & Repurpose"
      theme="light"
      footer="relative"
    >
      <CartContents />
    </Layout>
  );
}

export default CartPage;
